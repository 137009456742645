<template>
    <div>
        <v-card
            title="This is a title"
            elevation="2"
            outlined
            
            class="ma-2">
            <v-card-title>
                Filtros
            </v-card-title>
            <v-card-text>
                <v-form  lazy-validation>
                    <v-row dense>
                    <v-col cols="6" md="4" lg="2">
                        <v-select
                        class="extra_padding"
                        label="Canal"
                        item-text="canal"
                        item-value="canal"
                        :items="canales"
                        v-model="filtros.canal"
                        multiple
                        outlined
                        dense
                        >
                    </v-select>
                    </v-col>
                    <v-col cols="6" md="4" lg="2">
                        <v-select
                        class="extra_padding"
                        label="Ramo"
                        :items="ramos"
                        item-text="ramo"
                        item-value="ramo"
                        v-model="filtros.ramo"
                        multiple
                        outlined
                        dense
                        ></v-select>
                    </v-col>
                    <v-col cols="6" md="4" lg="2">
                        <v-select
                        label="Campaña"
                        class="extra_padding"
                        :items="currentCampanias"
                        v-model="filtros.campania"
                        item-text="producto"
                        item-value="id"
                        multiple
                        outlined
                        dense
                        ></v-select>
                    </v-col>
                    <v-col cols="6" md="4" lg="3">
                        <v-select
                        label="Dispociones"
                        class="extra_padding"
                        :items="disposiciones"
                        v-model="filtros.disposicion"
                        item-text="disposicion"
                        item-value="disposicion"
                        multiple
                        outlined
                        dense
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="4" lg="3">
                        <v-dialog
                            ref="dialog"
                            v-model="modal"
                            :return-value.sync="filtros.fechas"
                            persistent
                            width="290px"
                        >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="filtros.fechas"
                                label="Rango de fechas"
                                outlined
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="filtros.fechas"
                            range
                            scrollable
                            locale="es"
                        >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="modal = false"
                            >
                            Cancelar
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.dialog.save(filtros.fechas)"
                                >
                            OK
                            </v-btn>
                        </v-date-picker>
                        </v-dialog>
                    </v-col>
                    </v-row>
                    <v-row>
                    <v-col lg="8" cols="12" md="8" ></v-col>
                    <v-col cols="6" md="2" lg="2">
                        <v-btn
                            color="primary"
                            block
                            @click="getLeads()">
                            <v-icon small class="mr-2">mdi-magnify</v-icon>
                            <span>Buscar</span>
                        </v-btn>
                    </v-col>
                    <v-col cols="6" md="2" lg="2">
                        <v-btn
                            block
                            @click="clearFilters">
                        <v-icon small class="mr-2">mdi-delete</v-icon>
                        Limpiar 
                        </v-btn>
                    </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            </v-card-actions>
        </v-card> 
    </div>
</template>

<script>
import { mainAxios } from "../../../mainAxios";

export default {
props: {
    campanias: {
      type: Array,
      default: () => [],
    },
    cleanFiltersflag:{
        type: Boolean,
        default: false,
    }
  },
  data() {
    return {
        canales: [],
        ramos: [],
        filtros:{
            ramo: [],
            canal: [],
            campania: [],
            disposicion: [],
            fechas: [],
        },
        disposiciones: [],
        currentCampanias: [],
        showButtonFilter: true,
        modal:false
    };
  },
  mounted() {
    this.getCanales()
    this.getRamos()
    this.getDisposiciones()
  },   
  watch:{
    "filtros.canal"(){
      this.calculateCampains()      
    },
    "filtros.ramo"(){
      this.calculateCampains()      
    },     
    cleanFiltersflag(e){
        if(e){
            this.clearFilters()
        }
    }                                          
  },
  methods: {
    calculateCampains(){
        //var result = []
        var pas = []
        if(this.filtros.canal.length > 0 && this.filtros.ramo.length > 0){
            this.filtros.canal.forEach(canal => {
                var currentCanal = this.canales.find((e) => e.canal == canal).canal
                this.filtros.ramo.forEach(ramo => {
                    var currentRamo = this.ramos.find((e) => e.ramo == ramo).ramo
                    this.campanias.forEach(e => {
                        if(e.canal == currentCanal && e.ramo == currentRamo){
                            pas.push(e)
                        }
                    })
                })
            })
        }
        this.currentCampanias = pas
    },
    getCanales() { 
        this.loading = true;
        const config = {
        headers: {
            Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
        };
        mainAxios.get("/v1/canal/list", config).then((response) => {
        this.canales = response.data;
        this.loading = false;
        });
    },
    getRamos() {
        this.loading = true;
        const config = {
        headers: {
            Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
        };
        mainAxios.get("/v1/ramo/list", config).then((response) => {
        this.ramos = response.data;
        this.loading = false;
        });
    },
    clearFilters(){
        this.filtros.ramo = []
        this.filtros.canal = []
        this.filtros.campania = []
        this.filtros.disposicion = []
        this.filtros.fechas = []
    },
    getDisposiciones(){
            const config = {
                    headers: {
                    Authorization: "Bearer " + localStorage.agenteAccessToken,
                    }
                }
                mainAxios.get('/v1/disposiciones/list', config).then(response => {
                    response.data.map(element => {
                        this.disposiciones.push({
                            id: element.id,
                            disposicion: element.disposicion,
                            tipo_contacto: element.tipoContacto
                    });
                }) 
            })    
    },
    getLeads(){
        Object.keys(this.filtros).forEach(element => {
            if(this.filtros[element].length == 0)
                delete this.filtros[element];
        })
        this.$emit("filters", this.filtros);
    }, 

  },
};
</script>
<style>
.extra_padding .v-select__selections{
    padding: 11px 0 !important;
}

</style>