<template>
    <v-card class="mt-6 ml-2 mr-2">
        <v-toolbar>
        <v-toolbar-title>
            <span>Resultados</span>
        </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <v-row>
                <v-col cols="12" md="2">
                    <v-checkbox
                        v-model="checkAll"
                        color="primary"
                        label="Seleccionar todos"
                        hide-details>
                    </v-checkbox>
                </v-col>
                <v-col cols="12" md="8">
                    <v-text-field
                        v-model="search"
                        prepend-icon="mdi-magnify"
                        label="Buscar   "
                        single-line
                        hide-details
                        variant="underlined">
                    </v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                    <v-btn
                        color="success"
                        @click="pushRecicle"
                        block
                        :disabled="hasRecicle">
                        <v-icon small class="mr-2">mdi mdi-format-list-bulleted-square</v-icon>
                        <span>Aplicar recicle</span>
                    </v-btn>
                </v-col>
            </v-row>

        <v-data-table
            :items="moldeableLeads"
            :headers="headers"
            no-data-text="Sin leads para mostrar"
            loading-text="Buscando leads..."
            :loading="loading"
            :search="search"
            @current-items="currentItems"
        >
        <template v-slot:item.recicle="{ item }">
            <v-btn icon small>
                <div>
                    <v-checkbox v-model="item.recicle" ></v-checkbox>
                </div>
            </v-btn>
          </template>
          <template v-slot:item.campaña="{ item }">
            {{ getCampaniaName(item.campaña) }}
          </template>
        <template v-slot:item.view="{ item }">
            <v-btn icon small>
              <v-icon small @click="changeRoute(item)">
                mdi-eye
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
import { mainAxios } from "../../../mainAxios";
export default {
  
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    leads: {
      type: Array,
      default: () => [],
    },
    campanias: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
        search: null,
        checkAll: false,
        current_leds:[],
        headers: [
        {
          text: "Reciclar",
          align: "center",
          value: "recicle",
        },
        {
          text: "Estatus",
          align: "center",
          value: "status",
        },
        {
          text: "Fecha lead",
          align: "center",
          value: "fecha_lead",
        },
        {
          text: "Teléfono",
          align: "center",
          value: "telefono",
        },
        {
          text: "Nombre",
          align: "center",
          value: "nombreCompleto",
        },
        {
          text: "Canal",
          align: "center",
          value: "canal",
        },
        {
          text: "Ramo",
          align: "center",
          value: "ramo",
        },
        {
          text: "Campaña",
          align: "center",
          value: "campaña",
        },
        {
          text: "Ultima llamada",
          align: "center",
          value: "ulmimaLlamada",
        },
        {
          text: "No. Intentos",
          align: "center",
          value: "intentos",
        },
        {
          text: "Operador",
          align: "center",
          value: "operador",
        },
        {
          text: "Ver",
          align: "end",
          value: "view",
        },
        ],
        currentTotalInTable: [],
        moldeableLeads: []

    };
  },
  mounted() {
    this.current_leds = this.leads;
  },
  computed: {
    hasRecicle(){
        var ids = this.aplicarrecicle()
        if (ids.length > 0)
            return false
        else
            return true
    }
  },
  watch:{
    checkAll(value){
        this.moldeableLeads = []
        this.current_leds.forEach((element) => {
            this.moldeableLeads.push({
                id:element.id,
                recicle: value,
                uuid: element.uuid,
                status: element.status,
                fecha_lead: element.fecha_lead,
                telefono: element.telefono,
                nombreCompleto: element.nombreCompleto,
                canal: element.canal,
                ramo: element.ramo,
                campaña: element.campaña,
                ulmimaLlamada: element.ulmimaLlamada,
                intentos: element.intentos,
                operador: element.operador,
            })
        })
    },
    'leads'(e){
      this.moldeableLeads = e
      this.current_leds = e    
    }
  },    

  methods: {
    aplicarrecicle(){
        var idsRecicle = []
        this.moldeableLeads.forEach((element) => {
            if(element.recicle){
                idsRecicle.push(element.id)
            }
        })
        return idsRecicle;
    },
    pushRecicle() {
        var ids = this.aplicarrecicle()
        if(ids.length > 0){
          this.$swal({
            title: 'Estas seguro que quieres aplicar el recicle a: ' + ids.length + ' leds',
            type: 'success',
            showCancelButton: true,
            confirmButtonText: 'Si, aplicalo.',
            cancelButtonText: 'No, regresa.',
            showCloseButton: true,
            showLoaderOnConfirm: true,
          }).then((result) => {
            if(result.value) {
              this.sendLeds(ids)
            } 
          })
        }
      },
      currentItems(e){
        this.currentTotalInTable = e
      },
      sendLeds(ids){
        const config = {
            headers: {
                Authorization: "Bearer " + localStorage.agenteAccessToken,
            }
        }
        const data = {
          ids:ids,
        }
        mainAxios.post('/v1/origenLead/recicle',data,config).then(() => {
          this.$swal('Completado', 'Se aplico el proceso correctamente','success')
          this.$emit("cleanFilters")
        }) 
      },
      getCampaniaName(id){
        return this.campanias.find((e) => e.id == id).producto
      },
  },
};
</script>